const mixin = {
  data: function() {
    return {};
  },
  computed: {
    statuses() {
      return [
        {
          value: 'in preparation',
          text: this.$t('shipment.status.in preparation')
        },
        { value: 'to send', text: this.$t('shipment.status.to send') },
        { value: 'sent', text: this.$t('shipment.status.sent') },
        { value: 'canceled', text: this.$t('shipment.status.canceled') }
      ];
    }
  },
  methods: {
    transform(s) {
      return {
        ...s
      };
    },

    tStatus(status) {
      const key = `shipment.status.${status}`;
      if (this.$te(key)) {
        return this.$t(key);
      }
      return status;
    }
  }
};
export default mixin;
